<template>
  <div class="page">
    <Navbar title="基础资料" type="SMP" />
    <van-form @submit="submit" class="form">
      <van-cell-group>
        <van-field
          v-model="mlife.experience.name"
          name="婚姻经历"
          label="婚姻经历"
          readonly
          placeholder="请选择婚姻经历"
          :rules="[{ required: true, message: '请选择婚姻经历' }]"
          @click="experienceShow = true"
        />
        <van-popup :show="experienceShow" position="bottom">
          <van-picker
            :columns="experienceList"
            :columns-field-names="{
              text: 'name',
            }"
            @confirm="confirmExperience"
            @cancel="experienceShow = false"
          />
        </van-popup>
        <van-field
          v-model="mlife.marry.name"
          name="结婚时间"
          label="结婚时间"
          readonly
          placeholder="请选择结婚时间"
          :rules="[{ required: true, message: '请选择结婚时间' }]"
          @click="marryShow = true"
        />
        <van-popup :show="marryShow" position="bottom">
          <van-picker
            :columns="marryList"
            :columns-field-names="{
              text: 'name',
            }"
            @confirm="confirmMarry"
            @cancel="marryShow = false"
          />
        </van-popup>
        <van-field
          v-model="mlife.child.name"
          name="是否有孩子"
          label="是否有孩子"
          readonly
          placeholder="请选择结婚时间"
          :rules="[{ required: true, message: '请选择结婚时间' }]"
          @click="childShow = true"
        />
        <van-popup :show="childShow" position="bottom">
          <van-picker
            :columns="childList"
            :columns-field-names="{
              text: 'name',
            }"
            @confirm="confirmChild"
            @cancel="childShow = false"
          />
        </van-popup>
        <van-field
          v-model="mlife.support.name"
          readonly
          clickable
          name="赡养老人"
          label="赡养老人"
          placeholder="点击选择赡养老人"
          @click="supportShow = true"
        />
        <van-popup :show="supportShow" position="bottom">
          <van-picker
            :columns="supportList"
            :columns-field-names="{
              text: 'name',
            }"
            @confirm="confirmSupport"
            @cancel="supportShow = false"
          />
        </van-popup>
        <van-field
          v-model="mlife.live.name"
          readonly
          clickable
          name="居住方式"
          label="居住方式"
          placeholder="点击选择居住方式"
          @click="liveShow = true"
        />
        <van-popup :show="liveShow" position="bottom">
          <van-picker
            :columns="liveList"
            :columns-field-names="{
              text: 'name',
            }"
            @confirm="confirmLive"
            @cancel="liveShow = false"
          />
        </van-popup>
        <van-field
          v-model="mlife.childbirth.name"
          name="生育计划"
          label="生育计划"
          placeholder="请输入生育计划"
          :rules="[{ required: true, message: '请选择生育计划' }]"
          @click="childbirthShow = true"
        />
        <van-popup :show="childbirthShow" position="bottom">
          <van-picker
            :columns="childbirthList"
            :columns-field-names="{
              text: 'name',
            }"
            @confirm="confirmChildbirth"
            @cancel="childbirthShow = false"
          />
        </van-popup>
      </van-cell-group>
      <van-row>
        <van-col offset="4" span="16" class="subbtn">
          <van-button
            :color="COLOR"
            size="small"
            icon="passed"
            native-type="submit"
          >
            提交保存
          </van-button>
        </van-col>
      </van-row>
    </van-form>
    <Share ref="share" />
    <Loading :show="loadingShow" />
  </div>
</template>
<script>
import { Form, Field, CellGroup, Picker, Popup } from 'vant'
import Navbar from '../../common/Navbar.vue'
import Share from '../../common/Share.vue'
import Loading from '../../common/Loading.vue'
export default {
  components: {
    Navbar: Navbar,
    Share: Share,
    Loading: Loading,
    [Form.name]: Form,
    [Field.name]: Field,
    [CellGroup.name]: CellGroup,
    [Picker.name]: Picker,
    [Popup.name]: Popup
  },
  data () {
    return {
      personCode: '',
      marryList: [{ code: '', name: '未选择' }],
      marryShow: false,
      childList: [{ code: '', name: '未选择' }],
      childShow: false,
      liveList: [{ code: '', name: '未选择' }],
      liveShow: false,
      supportList: [{ code: '', name: '未选择' }],
      supportShow: false,
      childbirthList: [{ code: '', name: '未选择' }],
      childbirthShow: false,
      experienceList: [{ code: '', name: '未选择' }],
      experienceShow: false,
      mlife: {
        marry: { code: '', name: '' },
        child: { code: '', name: '' },
        live: { code: '', name: '' },
        support: { code: '', name: '' },
        childbirth: { code: '', name: '' },
        experience: { code: '', name: '' }
      },
      loadingShow: false
    }
  },
  mounted () {
    var query = this.$route.query
    var code = query.personCode
    if (code !== '' && code !== undefined && code !== null) {
      this.personCode = code
      this.retrieveAttr()
    }
    this.retrieveMarryOption()
    this.retrieveChildOption()
    this.retrieveLiveOption()
    this.retrieveSupportOption()
    this.retrieveChildbirthOption()
    this.retrieveExperienceOption()
    this.$refs.share.default('main')
  },
  methods: {
    confirmExperience (value) {
      this.mlife.experience.code = value.code
      this.mlife.experience.name = value.name
      this.experienceShow = false
    },
    confirmMarry (value) {
      this.mlife.marry.code = value.code
      this.mlife.marry.name = value.name
      this.marryShow = false
    },
    confirmChild (value) {
      this.mlife.child.code = value.code
      this.mlife.child.name = value.name
      this.childShow = false
    },
    confirmLive (value) {
      this.mlife.live.code = value.code
      this.mlife.live.name = value.name
      this.liveShow = false
    },
    confirmSupport (value) {
      this.mlife.support.code = value.code
      this.mlife.support.name = value.name
      this.supportShow = false
    },
    confirmChildbirth (value) {
      this.mlife.childbirth.code = value.code
      this.mlife.childbirth.name = value.name
      this.childbirthShow = false
    },
    async submit () {
      this.loadingShow = true
      var attrs = [
        { key: 'PSN_MLF_MARRY', type: 'DTL', content: this.mlife.marry, seq: '1' },
        { key: 'PSN_MLF_LIVE', type: 'DTL', content: this.mlife.live, seq: '1' },
        { key: 'PSN_MLF_SUPPORT', type: 'DTL', content: this.mlife.support, seq: '1' },
        { key: 'PSN_MLF_CHILD', type: 'DTL', content: this.mlife.child, seq: '1' },
        { key: 'PSN_MLF_CHILDBIRTH', type: 'DTL', content: this.mlife.childbirth, seq: '1' },
        { key: 'PSN_MLF_EXPERIENCE', type: 'DTL', content: this.mlife.experience, seq: '1' }
      ]
      var pd = {
        personCode: this.personCode,
        attrs: JSON.stringify(attrs)
      }
      const { data: res } = await this.$http.post(this.BMS_URL + '/people/person/editPersonAttr', this.$qs.stringify(pd))
      this.loadingShow = false
      if (res.status === '200') {
        this.$dialog.alert({
          title: '基础资料',
          message: '保存成功'
        }).then(() => {
          this.$router.go(-1)
        })
      } else {
        this.$dialog.alert({
          title: '基础资料',
          message: res.msg
        }).then(() => {
        })
      }
    },
    async retrieveAttr () {
      var pd = { personCode: this.personCode, group: 'MLF', type: 'DTL' }
      const { data: res } = await this.$http.post(this.BMS_URL + '/people/person/retrievePersonAttrGroupList', this.$qs.stringify(pd))
      if (res.status === '200') {
        res.data.forEach(element => {
          var obj = JSON.parse(element.content)
          if (element.key === 'PSN_MLF_MARRY') {
            this.mlife.marry.code = obj.code
            this.mlife.marry.name = obj.name
          } else if (element.key === 'PSN_MLF_LIVE') {
            this.mlife.live.code = obj.code
            this.mlife.live.name = obj.name
          } else if (element.key === 'PSN_MLF_SUPPORT') {
            this.mlife.support.code = obj.code
            this.mlife.support.name = obj.name
          } else if (element.key === 'PSN_MLF_CHILD') {
            this.mlife.child.code = obj.code
            this.mlife.child.name = obj.name
          } else if (element.key === 'PSN_MLF_CHILDBIRTH') {
            this.mlife.childbirth.code = obj.code
            this.mlife.childbirth.name = obj.name
          } else if (element.key === 'PSN_MLF_EXPERIENCE') {
            this.mlife.experience.code = obj.code
            this.mlife.experience.name = obj.name
          }
        })
      }
    },
    async retrieveMarryOption () {
      var pd = { key: 'MARRY_PLAN' }
      const { data: res } = await this.$http.post(this.BMS_URL + '/data/option/retrieveOptions', this.$qs.stringify(pd))
      if (res.status === '200') {
        this.marryList = res.data.item
      }
    },
    async retrieveChildOption () {
      var pd = { key: 'CHILD_NUMBER' }
      const { data: res } = await this.$http.post(this.BMS_URL + '/data/option/retrieveOptions', this.$qs.stringify(pd))
      if (res.status === '200') {
        this.childList = res.data.item
      }
    },
    async retrieveLiveOption () {
      var pd = { key: 'LIVE_MODE' }
      const { data: res } = await this.$http.post(this.BMS_URL + '/data/option/retrieveOptions', this.$qs.stringify(pd))
      if (res.status === '200') {
        this.liveList = res.data.item
      }
    },
    async retrieveSupportOption () {
      var pd = { key: 'SUPPORT_MODE' }
      const { data: res } = await this.$http.post(this.BMS_URL + '/data/option/retrieveOptions', this.$qs.stringify(pd))
      if (res.status === '200') {
        this.supportList = res.data.item
      }
    },
    async retrieveChildbirthOption () {
      var pd = { key: 'CHILDBIRTH_PLAN' }
      const { data: res } = await this.$http.post(this.BMS_URL + '/data/option/retrieveOptions', this.$qs.stringify(pd))
      if (res.status === '200') {
        this.childbirthList = res.data.item
      }
    },
    async retrieveExperienceOption () {
      var pd = { key: 'MARRY_EXPERIENCE' }
      const { data: res } = await this.$http.post(this.BMS_URL + '/data/option/retrieveOptions', this.$qs.stringify(pd))
      if (res.status === '200') {
        this.experienceList = res.data.item
      }
    }
  }
}
</script>
<style lang="less" scoped>
.step {
  text-align: left;
}
.form {
  padding: 0px 5px;
  margin-bottom: 30px;
}
.title {
  font-size: 13px;
  padding: 8px 0px 0px 10px;
  font-weight: 600;
  text-align: left;
  color: #999;
  height: 30px;
  line-height: 30px;
}
.subbtn {
  margin: 20px auto;
}
</style>
